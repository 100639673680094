<template>
  <div class="page-container">
    <div class="container">
      <b-alert dismissible fade :variant="alertVariant" :show="dismissCountDown" @dismiss-count-down="countDownChanged">
        {{ alertText }}
      </b-alert>
      <div v-if="loading">
        <div class="loader">
          <b-spinner label="Spinning" />
        </div>
      </div>
      <div v-else>
        <b-row align-h="between">
          <h1 class="title-1 pt-4 ml-4 ">
            {{ $t('organisationsAndDomains') }}
          </h1>
        </b-row>
        <div id="organizationTable">
          <b-table class="table-style table-settings" hover :items="organisations" :fields="fields" show-empty>
            <template #head(buttons)>
              <b-button size="sm" @click="addRow()">+ Add</b-button>
            </template>
            <template #cell(buttons)="row">
              <b-button size="sm" class="mr-2" @click="editRow(row.item)">
                Edit
              </b-button>
              <b-button size="sm" variant="danger" class="mr-2" @click="showConfirmationModal(row.item)">
                Remove
              </b-button>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <b-modal id="edit" title="Add/Edit Organisation">
      <template #modal-footer="{ cancel }">
        <b-button variant="danger" @click="cancel()">
          Discard changes
        </b-button>
        <b-button variant="success" :disabled="disable" @click="save()">
          Save
        </b-button>
      </template>
      Name
      <span v-b-tooltip.hover class="tip-icon" title="Name or the organisation">
        <font-awesome-icon icon="question-circle" />
      </span>
      <b-form-input v-model="organisation.name" @input="updateValue">
        ></b-form-input>
      <b-form-invalid-feedback v-if="errorMsgs.nameError">
        {{ errorMsgs.nameError }}
      </b-form-invalid-feedback>
      Identity provider alias
      <span v-b-tooltip.hover class="tip-icon" title="Identity provider alias">
        <font-awesome-icon icon="question-circle" />
      </span>
      <b-form-input v-model="organisation.idp_hint" @input="updateValue"></b-form-input>
      <b-form-invalid-feedback v-if="errorMsgs.idp_hintError">
        {{ errorMsgs.idp_hintError }}
      </b-form-invalid-feedback>
      logo url
      <span v-b-tooltip.hover class="tip-icon"
        title="Url of the organisations logo. Needs to be from a trusted domain.">
        <font-awesome-icon icon="question-circle" />
      </span>
      <b-form-input v-model="organisation.logo_url" type="url" @input="updateValue"></b-form-input>
      <b-form-invalid-feedback v-if="errorMsgs.logeUrlError">
        {{ errorMsgs.logeUrlError }}
      </b-form-invalid-feedback>
      logo url with transparent background
      <span v-b-tooltip.hover class="tip-icon"
        title="Url of the organisations logo with a transparent background. Needs to be from a trusted domain.">
        <font-awesome-icon icon="question-circle" />
      </span>
      <b-form-input v-model="organisation.logo_url_transparent" type="url" @input="updateValue"></b-form-input>
      <b-form-invalid-feedback v-if="errorMsgs.transparentLogoUrlError">
        {{ errorMsgs.transparentLogoUrlError }}
      </b-form-invalid-feedback>
      Support Email
      <span v-b-tooltip.hover class="tip-icon" title="Support email of organisation">
        <font-awesome-icon icon="question-circle" />
      </span>
      <b-form-input v-model="organisation.support_email" @input="updateValue"></b-form-input>
      <b-form-invalid-feedback v-if="errorMsgs.supportEmailError">
        {{ errorMsgs.supportEmailError }}
      </b-form-invalid-feedback>
      subdomains
      <span v-b-tooltip.hover class="tip-icon" title="Comma separated list of subdomains, e.g. abc.com, def.com">
        <font-awesome-icon icon="question-circle" />
      </span>
      <b-form-input v-model="subdomains" title="comma separated list of subdomains, e.g. abc.com, def.com"
        @input="updateValue"></b-form-input>
      <b-form-invalid-feedback v-if="errorMsgs.subdomainsError">
        {{ errorMsgs.subdomainsError }}
      </b-form-invalid-feedback>
    </b-modal>
    <b-modal id="modal-remove" hide-footer>
      <div class="d-block text-center">
        <p>{{ $t('deleteConfirmation') }}</p>
        <b-button variant="danger" class="mt-1" @click="$bvModal.hide('modal-remove')">
          {{ $t('no') }}
        </b-button>
        <b-button class="mt-1" style="margin-left:20px" primary @click="removeOrganization">
          {{ $t('yes') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import validUrl from 'valid-url'
import OkkuApi from '@/services/OkkuApi'
import { emailRegex } from '@/constants'

export default {
  name: 'OrganisationsPage',
  data() {
    return {
      organisations: [],
      organisation: {},
      fields: [
        {
          key: 'name',
          label: this.$t('organisation'),
          sortable: true
        },
        {
          key: 'idp_hint',
          label: this.$t('identityProvider'),
          sortable: true
        },
        {
          key: 'subdomains',
          label: this.$t('subdomainMappings'),
          sortable: true
        },

        {
          key: 'logo_url',
          label: this.$t('logoUrl'),
          sortable: true
        },
        {
          key: 'logo_url_transparent',
          label: this.$t('logoUrlTransparent'),
          sortable: true
        },
        {
          key: 'support_email',
          label: this.$t('supportEmail'),
          sortable: true
        },
        { key: 'buttons', label: '' }
      ],
      dismissCountDown: 0,
      alertVariant: 'success',
      alertText: '',
      mapping: {},
      loading: true,
      errorMsgs: {},
      disable: true,
      subdomains: ''
    }
  },
  mounted() {
    this.getOrgMappings()
  },
  methods: {
    async getOrgMappings() {
      this.organisations = await OkkuApi.getOrganisations()
      this.loading = false
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showConfirmationModal(row) {
      this.mapping = row
      this.$bvModal.show('modal-remove')
    },
    async removeOrganization() {
      this.$bvModal.hide('modal-remove')
      const { id } = this.mapping
      const {
        data: message,
        status
      } = await OkkuApi.deleteOrgMappings(id)

      let variant = 'danger'
      if (status === 200) {
        variant = 'success'
        this.mapping = {}
        this.getOrgMappings()
      }
      this.updateAlert({
        variant,
        text: message
      })
    },
    editRow(row) {
      this.organisation = row
      this.subdomains = row.subdomains.join(', ')
      this.$bvModal.show('edit')
    },
    addRow() {
      this.organisation = {}
      this.subdomains = ''
      this.$bvModal.show('edit')
    },
    async save() {
      try {
        if (!this.formValidation()) {
          return
        }
        this.disable = true
        const subdomains = this.subdomains
          .split(',')
          .map(s => s.trim())
        const organisation = {
          name: this.organisation.name,
          logo_url: this.organisation.logo_url,
          logo_url_transparent: this.organisation.logo_url_transparent,
          idp_hint: this.organisation.idp_hint,
          support_email: this.organisation.support_email,
          subdomains
        }

        await OkkuApi.upsertOrganisation(organisation)
        this.organisations = await OkkuApi.getOrganisations()

        this.updateAlert({
          variant: 'success',
          text: this.$t('changesSaved')
        })
        this.$bvModal.hide('edit')
        this.errorMsgs = ''
        this.disable = false
      } catch (error) {
        this.updateAlert({
          variant: 'danger',
          text: this.$t('changesNotSaved')
        })
      }
    },
    updateAlert(args) {
      this.dismissCountDown = 3
      this.alertVariant = args.variant
      this.alertText = args.text
    },

    formValidation() {
      if (
        !this.organisation.support_email ||
        !emailRegex.test(this.organisation.support_email)
      ) {
        this.errorMsgs = {
          supportEmailError: 'Please enter a valid email address'
        }
        return false
      }
      if (!this.organisation.name) {
        this.errorMsgs = {
          nameError: 'Please enter name'
        }
        return false
      }
      if (
        this.organisation.idp_hint &&
        this.organisation.idp_hint.length > 255
      ) {
        this.errorMsgs = {
          idp_hintError: 'IDP hint should be less than 255 characters'
        }
        return false
      }
      if (this.organisation.name.length > 255) {
        this.errorMsgs = {
          nameError: 'Name should be less than 255 characters'
        }
        return false
      }
      if (
        !this.organisation.logo_url ||
        !validUrl.isUri(this.organisation.logo_url)
      ) {
        this.errorMsgs = {
          logeUrlError: 'Please enter a valid logo url'
        }
        return false
      }
      if (
        this.organisation.logo_url_transparent &&
        !validUrl.isUri(this.organisation.logo_url_transparent)
      ) {
        this.errorMsgs = {
          transparentLogoUrlError: 'Please enter a valid logo url'
        }
        return false
      }
      if (!this.subdomains) {
        this.errorMsgs = {
          subdomainsError: 'Please enter subdomains'
        }
        return false
      }
      return true
    },
    updateValue(value) {
      if (this.organisation !== value) {
        this.disable = true
      }
      this.disable = false
    }
  }
}
</script>

<style lang="scss">
#organizationTable {
  .table-settings.table-settings {
    th {
      text-align: center !important;
    }
  }
}

.invalid-feedback {
  display: block;
}
</style>
